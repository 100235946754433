import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/workplay-logo-with-shadow.png";
import "../index.css";

const { REACT_APP_LOGO_URL } = process.env;

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [showHome, setShowHome] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowHome(true);
    }, 100);
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleStartClick = () => {
    // Validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    if (isValid) {
      // Store the email in localStorage
      localStorage.setItem("userEmail", email);
      navigate("/game");
    } else {
      alert("Please enter valid email address.");
    }
  };

  return (
    <div className={`home-container ${showHome ? "visible" : ""}`}>
      <div className="text-white py-24 flex flex-col text-center justify-center items-center gap-y-8 px-10">
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img src={logo} className="h-10 cursor-pointer" alt="workplay-logo" />
        </a>
        <h1 className="text-2xl sm:text-3xl text-center font-bold">
          Welcome to the Slot Machine Game
        </h1>
        <p className=" w-64 sm:text-lg font-semibold md:w-80">
          Enter your email address and start the game to win exciting rewards
        </p>
        <input
          type="email"
          placeholder="e.g., abc@example.com"
          className="w-64 px-4 h-14 my-4 bg-slate-300 text-black text-center rounded-md outline-none border-2 border-gray-500"
          value={email}
          onChange={handleEmailChange}
          required
          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Email validation pattern
        />
        <button
          className="btn bg-cyan-400 text-xl border-2 border-white text-white  font-semibold p-2 w-36 rounded-full"
          onClick={handleStartClick}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default Home;
