import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import grapes from "../assets/icons8-grapes-66.png";
import bannana from "../assets/icons8-banana-64.png";
import orange from "../assets/icons8-orange-64.png";
import apple from "../assets/icons8-apple-64.png";
import pineapple from "../assets/icons8-pineapple-66.png";
import berry from "../assets/icons8-berry-64.png";
import mute from "../assets/icons8-mute-50.png";
import soundOn from "../assets/icons8-volume-up-50.png";
import rules from "../assets/icons8-question-mark-50.png";
import PopUp from "../Components/PopUp";
import Rules from "../Components/Rules";
import sound from "../assets/slot-machine-payout-81725-[AudioTrimmer.com].mp3";
import logo from "../assets/workplay-logo-with-shadow.png";

const { REACT_APP_API_URL, REACT_APP_LOGO_URL } = process.env;

const Game: React.FC = () => {
  const slotItems = [grapes, bannana, pineapple, berry, apple, orange];
  const [slotResults, setSlotResults] = useState<string[]>([
    bannana,
    grapes,
    orange,
  ]);

  // Define coupons for each fruit
  const coupons: { [key: string]: string } = {
    [grapes]: "50% off",
    [bannana]: "60% off",
    [pineapple]: "45% off",
    [berry]: "55% off",
    [apple]: "40% off",
    [orange]: "30% off",
  };

  const [showPopup, setShowPopup] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [Coupon, setCoupon] = useState("better luck next time");
  const [showRules, setShowRules] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [play, setPlay] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(new Audio(sound));
  const toastId:any = React.useRef(null);

  const showToastMessage = () => {
    toastId.current = toast("Please wait...", { autoClose: 3000,
      position: "top-center", });
  }; 

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const toggleRules = () => {
    setShowRules(!showRules);
  };

  const handleSlot = () => {
    setIsSpinning(true);
    const audio = audioRef.current;
    audio.play();

    const resultItems: string[] = [];
    let isWinConditionMet = false;

    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * slotItems.length);
      const sameItem = slotItems[randomIndex];

      for (let i = 0; i < 3; i++) {
        if (Math.random() < 0.7) {
          resultItems[i] = sameItem;
        } else {
          const differentIndex = Math.floor(Math.random() * slotItems.length);
          resultItems[i] = slotItems[differentIndex];
        }
      }
      setSlotResults([...resultItems]);
    }, 100);

   

    const sendMail = () => {
      const subject = `Great News! You won a coupon!`;
      const mail = localStorage.getItem("userEmail");
      const coupon = localStorage.getItem("Coupon");
      axios
        .post(`${REACT_APP_API_URL}/sendEmail`, {
          mail: mail,
          subject: subject,
          message: `Congratulations! You've won a ${coupon} discount coupon on the Slot Machine! Enjoy the rewards!`,
        })
        .then((response) => {
          console.log("Mail sent successfully:", response.data);
          toast.update(toastId.current, {
            render: "Your Coupon is on the Way",
            type: 'success',
            autoClose: 3000,
            position: "top-center",
          });
        })
        .catch((error) => {
          console.error("Error sending mail:", error);
        });
      return;
    };



    const storeData = () => {
      const mail = localStorage.getItem("userEmail");
      const coupon = localStorage.getItem("Coupon");
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
      if (mail && coupon && timestamp) {
        const gameResultData = {
          mail: mail,
          coupon: coupon,
          timestamp,
        };
        axios
          .post(`${REACT_APP_API_URL}/slot-result`, gameResultData)
          .then((response) => {
            console.log("Response:", response.data.message);
          })
          .catch((error: any) => {
            console.error("Error sending game result:", error);
          });
      }
    };

    // to disable play button
    setTimeout(() => {
      setPlay(true);
      setTimeout(() => {
        setPlay(false);
      }, 3000);
    }, 0);

    setTimeout(() => {
      clearInterval(interval);
      setHasPlayed(true);
      const allItemsSame =
        resultItems.every((item) => item === resultItems[0]) &&
        resultItems[0] !== "" &&
        resultItems.length === 3 &&
        !isSpinning;

      if (allItemsSame && !isWinConditionMet) {
        isWinConditionMet = true;
        setIsWin(true);
        // Get the coupon based on the winning fruit
        const selectedCoupon = coupons[resultItems[0]];
        // Store the coupon in localStorage
        localStorage.setItem("Coupon", selectedCoupon);
        sendMail();
        storeData();
        setTimeout(() => {
          setShowPopup(true);
        }, 800);
      } else {
        setIsWin(false);
        setTimeout(() => {
          setShowPopup(true);
        }, 800);
      }
      setIsSpinning(false);
      localStorage.clear();
    }, 2000);
  };
 

  useEffect(() => {
    // Check for winning conditions when slotResults change
    if (
      slotResults.every((item) => item === slotResults[0]) &&
      slotResults[0] !== "" &&
      slotResults.length === 3 &&
      !isSpinning
    ) {
      // Get the coupon based on the winning fruit
      setCoupon(coupons[slotResults[0]]);
    }
  }, [slotResults, isSpinning]);

  return (
    <div className="flex flex-col  gap-y-8 items-center my-24 ">
      <div>
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img src={logo} className="h-10 cursor-pointer" alt="Workplay-logo" />
        </a>
      </div>
      <div className="flex  flex-col  items-center  justify-center  ">
        <div className="slot-machine  flex flex-col mx-4 h-80 justify-center items-center my-1  bg-cyan-400 border-8 rounded-md  w-72 sm:w-96 sm:h-96">
          <div className="w-56 sm:w-80 h-14 border-4  text-center text-green-700 py-2 bg-gray-800 border-gray-600">
            <audio ref={audioRef} />
            {hasPlayed && (
              <div className="lcd-font  w-11/12 text-center p-0 font-semibold my-1 mx-auto text-l sm:text-xl ">
                {isWin ? (
                  <p className="uppercase"> hurray, you won !</p>
                ) : (
                  <p className="uppercase">sorry, you lost!</p>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col-3 gap-x-2 border-4 bg-black h-auto w-aut0 p-2 justify-center items-center px-auto my-10 ">
            {slotResults.map((item, index) => (
              <img
                className="box-shadow  p-1 bg-gray-200  rounded-sm h-16 w-16 sm:h-24 sm:w-24 "
                src={item}
                alt={`Slot item ${index + 1}`}
              />
            ))}
          </div>
          <div className="flex flex-row gap-x-5 justify-between items-center">
            <button
              onClick={() => toggleMute()}
              className="btn bg-yellow-400  border-2 border-white p-2 sm:p-4 rounded-full"
            >
              <img
                src={isMuted ? mute : soundOn}
                className=" h-5 sm:h-6"
                alt="mute"
              />
            </button>
            <button
              onClick={() => {
                handleSlot();
                setHasPlayed(false);
              }}
              disabled={play}
              type="button"
              className="btn text-sm sm:text-xl  text-black font-semibold  border-2 border-white bg-yellow-400 py-2  w-24 sm:w-36   rounded-full"
            >
              PLAY
            </button>
            <button
              disabled={play}
              onClick={toggleRules}
              className="btn  bg-yellow-400  border-2 border-white p-2 sm:p-4 rounded-full"
            >
              <img src={rules} className=" h-5 sm:h-6" alt="rules" />
            </button>
          </div>
        </div>
        <audio ref={audioRef} src={sound} muted={isMuted} />
      </div>
      {showRules && <Rules closeFunction={setShowRules} />}
      {showPopup && <PopUp  showToastMessage={showToastMessage } prize={Coupon} />}
    </div>
  );
};

export default Game;
