
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Game from "../src/Components/Game"
import Home from "../src/Components/Home"

function App() {
  return (
    <BrowserRouter><Routes>
    <Route path='/'  element={<Home/>}></Route>
    <Route path='game'  element={<Game/>}></Route>
    </Routes></BrowserRouter>
  );
}

export default App;
