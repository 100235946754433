import React, { useState } from "react";
import "../Styles/Rules.css";

interface RulesProps {
  closeFunction: React.Dispatch<React.SetStateAction<boolean>>;
}

const Rules = ({ closeFunction }: RulesProps) => {
  const [showPopup, setShowPopup] = useState(true);

  const togglePopup = () => {
    closeFunction(false);
    setShowPopup(!showPopup);
  };

  return (
    <>
      {showPopup && (
        <div className="overlay z-40 fixed inset-0 bg-black opacity-50"></div>
      )}

      <div className="z-50">
        {showPopup && (
          <div className="rules-container flex flex-col z-10 mx-auto items-center justify-evenly h-96 w-80 sm:w-96 px-8 z-80 rounded-md border-4 bg-yellow-300 border-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="uppercase text-center text-2xl font-extrabold">
              Slot Machine Rules
            </h2>
            <div className="rules">
              <ul className="text-start px-4 sm:text-lg list-decimal">
                <li className="mb-2">Match all three items to win.</li>
                <li className="mb-2">
                  Matching all three items earns you a special coupon.
                </li>
                <li className="mx-1">No win if the items don't match.</li>
              </ul>
            </div>
            <button
              className="btn bg-cyan-400 text-xl text-white border-2 border-white font-semibold p-2 w-36 rounded-full"
              onClick={togglePopup}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Rules;
